import type * as Types from '../../models/types/types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DocumentsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type DocumentsQueryResult = {
  documents?: Array<{
    id?: string | null;
    name?: string | null;
    context?: string | null;
    data?: any | null;
    schemaVersion?: number | null;
  } | null> | null;
};

export const DocumentsDocument = gql`
  query Documents($context: String) {
    documents(filter: { context: $context }) {
      id
      name
      context
      data
      schemaVersion
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DocumentsQueryService extends Apollo.Query<DocumentsQueryResult, DocumentsQueryVariables> {
  document = DocumentsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
