import { FilterInput } from './filter-input';
import { SortOption } from './sort-option';
import {
  Document,
  EmployeeFilterKeys,
  EmployeeSortInput,
  OpportunityFilterKeys,
  OpportunitySortInput,
  ProjectFilterKey,
  ProjectSortInput,
} from './types.generated';
import { AdvancedFilterInput } from './advanced-filter-input';

export type TypedDocument<TFilterInput extends FilterInput, TSortInput extends SortOption> = Omit<
  Required<Document>,
  '__typename' | 'data'
> & {
  data: Partial<TFilterInput & TSortInput>;
};

export enum DocumentContext {
  OPPORTUNITIES_FILTER = 'opportunities-filter',
  PROJECTS_FILTER = 'projects-filter',
  EMPLOYEES_FILTER = 'employees-filter',
}

export type DashboardCheckBox = {
  showOnDashboard: boolean;
};

export interface DocumentWithContext {
  [DocumentContext.OPPORTUNITIES_FILTER]: TypedDocument<
    AdvancedFilterInput<OpportunityFilterKeys>,
    OpportunitySortInput & DashboardCheckBox
  >;

  [DocumentContext.PROJECTS_FILTER]: TypedDocument<
    AdvancedFilterInput<ProjectFilterKey>,
    ProjectSortInput & DashboardCheckBox
  >;

  [DocumentContext.EMPLOYEES_FILTER]: TypedDocument<
    AdvancedFilterInput<EmployeeFilterKeys>,
    EmployeeSortInput & DashboardCheckBox
  >;
}

export type DashboardComponentUrl = {
  base: string;
  params: {
    filter: any;
    sort: any;
  };
};

export type DashboardComponent = {
  id: string;
  titleKey?: string;
  iconName: string;
  tooltipKey?: string;
  filterDoc?: Document;
  url?: DashboardComponentUrl;
  sortPosition?: number;
};
