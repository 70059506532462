import type * as Types from '../../models/types/types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateDocumentMutationVariables = Types.Exact<{
  document: Types.DocumentInput;
}>;

export type CreateDocumentMutationResult = { createDocument?: { id?: string | null } | null };

export type UpdateDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  document: Types.DocumentInput;
}>;

export type UpdateDocumentMutationResult = { updateDocument?: { id?: string | null } | null };

export type DeleteDocumentMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DeleteDocumentMutationResult = { deleteDocument?: { id?: string | null } | null };

export const CreateDocumentDocument = gql`
  mutation CreateDocument($document: DocumentInput!) {
    createDocument(document: $document) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class CreateDocumentMutationService extends Apollo.Mutation<
  CreateDocumentMutationResult,
  CreateDocumentMutationVariables
> {
  document = CreateDocumentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateDocumentDocument = gql`
  mutation UpdateDocument($id: ID!, $document: DocumentInput!) {
    updateDocument(id: $id, document: $document) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UpdateDocumentMutationService extends Apollo.Mutation<
  UpdateDocumentMutationResult,
  UpdateDocumentMutationVariables
> {
  document = UpdateDocumentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteDocumentDocument = gql`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class DeleteDocumentMutationService extends Apollo.Mutation<
  DeleteDocumentMutationResult,
  DeleteDocumentMutationVariables
> {
  document = DeleteDocumentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
