import { inject, Pipe, PipeTransform } from '@angular/core';
import { DetailStatus } from '@shared/models/enums/status';
import { AppConfig, CrmProperties } from '@shared/models/interfaces/app-config';
import { APP_CONFIG } from 'src/app/configuration/configuration.module';

export enum CRM_ENTITY {
  OPPTY = 'oppty',
  PROJECT = 'project',
  EMPLOYEE = 'employee',
}

@Pipe({
  name: 'crmPropertyCheck',
})
export class CrmPropertyCheckPipe implements PipeTransform {
  private appConfig: AppConfig = inject(APP_CONFIG);

  properties: CrmProperties = this.appConfig.crmProperties;

  transform(status: DetailStatus, isCrmEntity: boolean, entity: CRM_ENTITY, property: string): DetailStatus {
    if (status === DetailStatus.LOAD || !isCrmEntity) {
      return status;
    }
    return this.properties[entity].find((crm_prop: string) => crm_prop.toLowerCase() === property.toLowerCase()) !==
      undefined
      ? DetailStatus.READ
      : status;
  }
}
