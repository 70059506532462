/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { combineLatest, filter } from 'rxjs';
import { NavigationService } from '@shared/services/navigation.service';
import { SortService } from '@shared/services/sort.service';
import { AdvancedFilterInput } from '@shared/models/types/advanced-filter-input';
import { SortOption } from '@shared/models/types/sort-option';
import { isEqual } from 'lodash-es';
import { NotificationService } from './notification.service';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root',
})
export class QueryParamService {
  notificationService: NotificationService = inject(NotificationService);
  filterService: FilterService<any> = inject(FilterService);
  navigationService: NavigationService = inject(NavigationService);
  sortService: SortService = inject(SortService);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);

  constructor() {
    /*eslint-disable @typescript-eslint/no-unsafe-return */
    this.route.queryParams
      .pipe(filter((params: Params) => params.advancedFilter || params.filter))
      .subscribe((params: Params) => this.setFilter(params));

    combineLatest([
      this.filterService.filters$,
      this.filterService.searchTerm$,
      this.sortService.primarySortOption$,
      this.navigationService.inListRoute$,
    ])
      .pipe(
        filter(([, , , inListRoute]) => inListRoute), // query params should only be applied in list view
      )
      .subscribe(([filters, search, sortOptions]) => {
        this.encodeFilter(filters, search, sortOptions);
      });
  }

  setFilter(params: Params) {
    let filterAsJson: AdvancedFilterInput<any>;

    const entity: string = this.filterService.context?.toString().split('-')[0];

    if (this.router.url.includes(entity)) {
      const filterParam: string = params.advancedFilter || params.filter;
      filterAsJson = JSON.parse(atob(filterParam));

      if (params.sort) {
        const sortOptionsAsJson: SortOption = JSON.parse(atob(params.sort as string));

        this.sortService.setPrimarySortOption(sortOptionsAsJson);
      }

      if (!filterAsJson.rootNode) {
        this.filterService.resetFilters();
        this.notificationService.addErrorNotification('errors:filters.oldFilter');
      } else {
        if (!isEqual(this.filterService.filters, filterAsJson)) {
          this.filterService.updateFilter(filterAsJson);
        }
      }

      if (params.search) {
        this.filterService.updateSearchTerm(params.search as string);
      }
    }
  }

  encodeFilter(filters: AdvancedFilterInput<any>, search: string, sortOptions: SortOption) {
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        filter: btoa(JSON.stringify(filters)),
        sort: btoa(JSON.stringify(sortOptions)),
        search,
      },
    });
  }
}
