import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CacheKey, LocalStorageService } from './local-storage.service';

export const FILTER_SIDEBAR_CACHE_KEY = new InjectionToken<CacheKey>('FILTER_SIDEBAR_CACHE_KEY');

export enum SidebarTab {
  FILTER,
  SAVED_FILTER,
}

@Injectable({
  providedIn: 'root',
})
export class FilterSidebarStatusService {
  private localStorageService: LocalStorageService = inject(LocalStorageService);

  public readonly isCollapsed$: Observable<boolean>;
  private _isCollapsed: BehaviorSubject<boolean>;

  private _activeSidebarTab = new BehaviorSubject<SidebarTab>(SidebarTab.FILTER);
  public readonly activeSidebarTab$ = this._activeSidebarTab.asObservable();

  constructor(@Inject(FILTER_SIDEBAR_CACHE_KEY) private cacheKey: CacheKey = CacheKey.FILTER_SIDEBAR_IS_COLLAPSED) {
    const savedStatus = this.localStorageService.loadKey(this.cacheKey, false);

    this._isCollapsed = new BehaviorSubject(Boolean(savedStatus));
    this.isCollapsed$ = this._isCollapsed.asObservable();
  }

  public toggleSidebar(): void {
    const newValue = !this._isCollapsed.getValue();

    this._isCollapsed.next(newValue);
    this.localStorageService.saveKey(this.cacheKey, newValue);
  }

  public get sidebarTab(): SidebarTab {
    return this._activeSidebarTab.getValue();
  }

  public setSidebarTab(tab: SidebarTab) {
    this._activeSidebarTab.next(tab);
  }
}
