import { xor, xorBy } from 'lodash-es';

export function moveItem<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
  const copyArray = [...arr];
  const element = copyArray[fromIndex];
  copyArray.splice(fromIndex, 1);
  copyArray.splice(toIndex, 0, element);
  return copyArray;
}

export function findById<T extends { id: string }>(arr: T[], id: string): T | undefined {
  return arr.find((item) => item.id === id);
}

export function mapToIds<T extends { id: string }>(arr: T[]) {
  return arr.map((item) => item.id);
}

export const insertAtIndex = <T>(arr: T[], index: number, newItem: T) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

/**
 * Adds/removes the item in the given list based on its existence in the list.
 * Optionally, a key can be provided to identify the item.
 * If the item does not exist in the list, it will be added to the first index.
 *
 * @param list  The current list
 * @param item  The item that should be toggled
 * @param key *(optional)* The key that should be used to compare the items.
 */
export const toggleItemInList = <T>(list: T[], item: T, key?: keyof T) => {
  if (key) {
    return xorBy(list, [item], key);
  }

  return xor(list, [item]);
};
