/* eslint-disable @typescript-eslint/only-throw-error */

import { NotificationService } from '@shared/services/notification.service';
import { DocumentWithContext } from '@shared/models/types/documents';
import { findById } from '@shared/utils/array-utils';
import { pickBy } from 'lodash-es';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  pairwise,
  startWith,
  Subscription,
  tap,
  throwError,
} from 'rxjs';
import { DocumentsService } from './documents.service';
import { SortService } from './sort.service';
import { GraphQLFormattedError } from 'graphql';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { FilterService } from './filter.service';

@Injectable()
export class SavedFilterService implements OnDestroy {
  private documentsService: DocumentsService = inject(DocumentsService);
  private filterService: FilterService<any> = inject(FilterService);
  private sortService: SortService = inject(SortService);
  private notificationService: NotificationService = inject(NotificationService);

  protected readonly CURRENT_SCHEMA_VERSION: number = 2;
  private subscription: Subscription;
  private _savedFilter = new BehaviorSubject<DocumentWithContext[typeof this.filterService.context][]>([]);
  private _newFilterCache = new BehaviorSubject<DocumentWithContext[typeof this.filterService.context]['data'] | null>(
    null,
  );
  private _lastSelectedFilter = new BehaviorSubject<string>(null);

  readonly savedFilter$ = this._savedFilter.asObservable();
  readonly newFilterCache$ = this._newFilterCache.asObservable();
  readonly lastSelectedFilter$ = this._lastSelectedFilter.asObservable();
  readonly currentlyActiveFilter$ = combineLatest([this.lastSelectedFilter$, this.filterService.filters$]).pipe(
    pairwise(),
    map(
      (
        [[oldFilterId], [newFilterId]], // check if the current filter differs from the selected one
      ) => (oldFilterId === newFilterId ? oldFilterId : newFilterId),
    ),
    startWith(null),
    distinctUntilChanged(),
  );

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  constructor() {
    this.subscription = this.documentsService
      .observeDocuments(this.filterService.context)
      .subscribe((data: DocumentWithContext[typeof this.filterService.context][]) => this._savedFilter.next(data));
  }

  createNewFilter(newName: string, showOnDashboard: boolean): Observable<string | null> {
    if (this.doesFilterNameAlreadyExist(newName)) {
      this.showErrorMessage('filters.duplicateFilterName', newName);
      return of(null);
    }

    const newFilter: DocumentWithContext[typeof this.filterService.context]['data'] = {
      ...this.filterService.filters,
      ...this.sortService.primarySortOption,
      showOnDashboard: showOnDashboard, // default value
    } as DocumentWithContext[typeof this.filterService.context]['data'];

    if (!newFilter) {
      return throwError(() => new Error('Should not call this method if no filter is cached.'));
    }

    return this.documentsService
      .createDocument({
        name: newName,
        context: this.filterService.context,
        data: newFilter,
        schemaVersion: this.CURRENT_SCHEMA_VERSION,
      })
      .pipe(
        map((response) => {
          if (!response.errors) {
            this.notificationService.addSuccessNotification('common:sidebar.saveSuccessfull', {
              i18nOptions: { name: newName },
            });

            return response.data.createDocument.id;
          } else throw response.errors.map((err: GraphQLFormattedError) => err.message);
        }),
        catchError((errors: string[]) => {
          errors.forEach((error: string) => this.showErrorMessage(error));
          return of(null);
        }),
      );
  }

  updateFilterToDashboard(id: string, showOnDashboard: boolean) {
    const savedFilters = this._savedFilter.getValue();
    const foundFilter = findById(savedFilters, id);

    if (foundFilter === undefined) {
      return throwError(() => new Error(`Could not be updated because no filter with the id ${id} was found.`));
    }

    const { context, schemaVersion, name } = foundFilter;
    const data = {
      ...foundFilter.data,
      showOnDashboard,
      sortPosition: showOnDashboard ? this.documentsService.dashboardDocuments().length : null,
    };

    return this.documentsService
      .updateDocument(id, {
        data,
        context,
        schemaVersion,
        name,
      })
      .pipe(
        map((response) => {
          if (response.data) return Boolean(response.data.updateDocument.id);
          else throw response.errors.map((err: GraphQLFormattedError) => err.message);
        }),
        catchError((errors: string[]) => {
          console.error(errors);
          errors.forEach((error: string) => this.showErrorMessage(error));
          return of(false);
        }),
      );
  }

  updateFilter(filterId: string, name: string, showOnDashboard: boolean): Observable<string | null> {
    const savedFilters = [...this._savedFilter.getValue()];
    const foundFilter = findById(savedFilters, filterId);

    if (foundFilter === undefined) {
      return throwError(
        () => new Error(`Filter could not be updated because no filter with the id ${filterId} was found.`),
      );
    }

    const newFilter: DocumentWithContext[typeof this.filterService.context]['data'] = {
      ...this.filterService.filters,
      ...this.sortService.primarySortOption,
      showOnDashboard: showOnDashboard, // default value
    } as DocumentWithContext[typeof this.filterService.context]['data'];

    return this.documentsService
      .updateDocument(filterId, {
        name,
        data: newFilter,
        context: foundFilter.context,
        schemaVersion: foundFilter.schemaVersion,
      })
      .pipe(
        map((response) => {
          if (!response.errors) {
            this.notificationService.addSuccessNotification('common:sidebar.editSuccessfull', {
              i18nOptions: { name },
            });

            return response.data.updateDocument.id;
          } else throw response.errors.map((err: GraphQLFormattedError) => err.message);
        }),
        catchError((errors: string[]) => {
          console.error(errors);
          errors.forEach((error: string) => this.showErrorMessage(error));
          return of(null);
        }),
      );
  }

  setSelectedFilterById(id: string): void {
    if (id === this._lastSelectedFilter.getValue()) return;

    const foundFilter: DocumentWithContext[typeof this.filterService.context] = findById(
      this._savedFilter.getValue(),
      id,
    );

    if (!foundFilter) throw new Error(`Filter with ID '${id}' could not be found.`);

    const filterValues = pickBy(foundFilter.data, (_, key) => this.filterService.isFilterKey(key));
    const sortValues = pickBy(foundFilter.data, (_, key) => this.sortService.isSortKey(key));

    this.filterService.updateFilter(filterValues);

    this.sortService.setPrimarySortOption(sortValues);
    this._lastSelectedFilter.next(id);
  }

  resetSelectedFilter(): void {
    this._lastSelectedFilter.next(null);
  }

  deleteFilter(id: string): Observable<boolean> {
    return this.documentsService.deleteDocument(id).pipe(
      map(({ data, errors }) => {
        if (data) {
          this.notificationService.addSuccessNotification('common:sidebar.deleteSuccessfull', {
            i18nOptions: { name },
          });

          return data?.deleteDocument.id;
        }
        if (errors) throw errors.map((err: GraphQLFormattedError) => err.message);
      }),
      tap((deletedId) => {
        if (deletedId === this._lastSelectedFilter.getValue()) this._lastSelectedFilter.next(null);
      }),
      map((deletedId) => Boolean(deletedId)),
      catchError((errors: string[]) => {
        console.error(errors);
        errors.forEach((error: string) => this.showErrorMessage(error));
        return of(false);
      }),
    );
  }

  private doesFilterNameAlreadyExist(name: string): boolean {
    return !!this._savedFilter.getValue().find((document) => document.name === name);
  }

  private showErrorMessage(error: string, name?: string): void {
    console.error(error);

    this.notificationService.addErrorNotification(`errors:filters.${error}`, {
      i18nOptions: { name },
    });
  }

  get currentFilter(): DocumentWithContext[typeof this.filterService.context] {
    return findById(this._savedFilter.getValue(), this._lastSelectedFilter.getValue());
  }
}
