import { FilterCriteria, FilterNode } from '@shared/models/types/advanced-filter-input';

export function hasSuitableFilter<K>(rootNode: FilterNode<K>, key: FilterCriteria<K>['key']): boolean {
  if (!rootNode) {
    return false;
  }
  const arr = [rootNode];
  while (arr.length) {
    const node = arr.shift();
    if (node.value) {
      if (node.value.key === key) return true;
    } else {
      arr.unshift(...node.children);
    }
  }
  return false;
}

export function getSuitableFilter<K>(rootNode: FilterNode<K>, key: FilterCriteria<K>['key']): FilterNode<K> {
  if (!rootNode) {
    return null;
  }
  const arr = [rootNode];
  while (arr.length) {
    const node = arr.shift();
    if (node.value) {
      if (node.value.key === key) {
        return node;
      }
    } else {
      arr.unshift(...node.children);
    }
  }
  return null;
}

export function replaceFilterValue<K>(node: FilterNode<K>, searchValue: string, replaceWith: string): FilterNode<K> {
  if (!node) {
    return null;
  }

  if (node.value && node.value.value.includes(searchValue)) {
    node.value.value = replaceWith;
  }

  if (node.children) {
    node.children.forEach((childNode) => replaceFilterValue(childNode, searchValue, replaceWith));
  }

  return node;
}
