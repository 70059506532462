import { Injectable } from '@angular/core';
import { SortOption } from '@shared/models/types/sort-option';
import { SortOrder } from '@shared/models/types/types.generated';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';

@Injectable()
export abstract class SortService {
  private _sortOptions = new BehaviorSubject(this.getDefaultSortOptions());
  readonly sortOptions$ = this._sortOptions
    .asObservable()
    .pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)));
  readonly primarySortOption$ = this.sortOptions$.pipe(map(([firstSortOption]) => firstSortOption));

  abstract getDefaultSortOptions(): SortOption[];
  abstract getDefaultSortOrderByField(field: string): SortOrder;
  abstract getDefaultSecondaryOptionByField(field: string): SortOption;
  abstract isSortField(field: string): boolean;

  get sortOptions(): SortOption[] {
    return this._sortOptions.getValue();
  }

  get primarySortOption(): SortOption {
    return this._sortOptions.getValue()[0];
  }

  setPrimarySortOption(sortOption: Partial<SortOption>): void {
    const safeSortOption: SortOption = {
      ...this.getDefaultSortOptions()[0],
      ...sortOption,
    };

    this.setSortField(safeSortOption.field);
    this.setSortOrder(safeSortOption.order);
  }

  setSortField(field: string, setDefaultSortOption = false): void {
    if (!this.isSortField(field)) {
      console.error(`Invalid sort field: ${field}`);
      return;
    }

    const currentSortOption = this.primarySortOption;
    const newSortOptions: SortOption[] = [
      {
        ...currentSortOption,
        field,
      },
    ];

    if (setDefaultSortOption) {
      newSortOptions[0].order = this.getDefaultSortOrderByField(field);
    }

    const secondarySortOption = this.getDefaultSecondaryOptionByField(field);

    if (secondarySortOption) {
      newSortOptions.push(secondarySortOption);
    }

    this._sortOptions.next(newSortOptions);
  }

  setSortOrder(order: SortOrder) {
    if (!Object.values(SortOrder).includes(order)) {
      console.error(`Invalid sort order: ${order}`);
      return;
    }

    this._sortOptions.next([
      {
        ...this.primarySortOption,
        order,
      },
      ...this.sortOptions.slice(1),
    ]);
  }

  isSortKey(key: string): boolean {
    return key === 'field' || key === 'order';
  }
}
