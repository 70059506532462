import { ProjectState } from '../types/types.generated';

export enum DetailStatus {
  READ = 'READ',
  EDIT = 'EDIT',
  LOAD = 'LOAD',
}

export const ACTIVE_PROJECT_STATES = [
  ProjectState.Email,
  ProjectState.Missing,
  ProjectState.Verbal,
  ProjectState.Written,
];
