/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import {
  DocumentsQueryResult,
  DocumentsQueryService,
} from '@shared/services/generated/documents.query.service.generated';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from '@shared/components/base/unsubscribe.component';
import { BehaviorSubject } from 'rxjs';
import { Document, DocumentInput } from '@shared/models/types/types.generated';
import { QueryRef } from 'apollo-angular';
import {
  CreateDocumentMutationService,
  DeleteDocumentMutationService,
  UpdateDocumentMutationService,
} from '@shared/services/generated/documents.mutations.service.generated';
import { DashboardComponent, DocumentContext, DocumentWithContext } from '@shared/models/types/documents';
import { RoutePaths } from 'src/app/app-routing.module';

const FILTER_TILE_ICON: Record<string, string> = {
  [DocumentContext.OPPORTUNITIES_FILTER]: 'opportunity',
  [DocumentContext.PROJECTS_FILTER]: 'partner',
  [DocumentContext.EMPLOYEES_FILTER]: 'employee',
};

const DOC_URL_BASE: Record<DocumentContext, RoutePaths> = {
  [DocumentContext.EMPLOYEES_FILTER]: RoutePaths.EMPLOYEES,
  [DocumentContext.OPPORTUNITIES_FILTER]: RoutePaths.OPPORTUNITIES,
  [DocumentContext.PROJECTS_FILTER]: RoutePaths.PROJECTS,
};

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends UnsubscribeComponent {
  private _documents = new BehaviorSubject<Document[]>([]);
  private documentsQuery: QueryRef<DocumentsQueryResult>;
  public readonly documents$ = this._documents.asObservable();

  private allDocuments: WritableSignal<Document[]> = signal<Document[]>([]);

  dashboardDocuments: Signal<DashboardComponent[]> = computed(() =>
    this.allDocuments()
      .filter((doc: Document) => doc.data?.showOnDashboard)
      .map(
        (doc: Document): DashboardComponent => ({
          id: doc.id,
          filterDoc: doc,
          url: {
            base: DOC_URL_BASE[doc.context],
            params: {
              filter: this.filterParams(doc),
              sort: this.sortParams(doc),
            },
          },
          iconName: FILTER_TILE_ICON[doc.context], // based on context
          sortPosition: doc.data?.sortPosition ?? null,
        }),
      )
      .sort((a, b) => a.sortPosition - b.sortPosition),
  );

  constructor(
    private documentQueryService: DocumentsQueryService,
    private createDocumentMutationService: CreateDocumentMutationService,
    private deleteDocumentMutationService: DeleteDocumentMutationService,
    private updateDocumentMutationService: UpdateDocumentMutationService,
  ) {
    super();
    this.documentsQuery = this.documentQueryService.watch();

    this.documentsQuery.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map(({ data }) => data?.documents || []),
      )
      .subscribe((docs: Document[]) => {
        this._documents.next(docs);
        this.allDocuments.set(docs);
      });
  }

  observeDocuments = <T extends DocumentContext>(context: T) =>
    this.documents$.pipe(
      map((documents) =>
        documents.filter((document): document is DocumentWithContext[T] => document.context === context),
      ),
    );

  createDocument = (documentInput: DocumentInput) =>
    this.createDocumentMutationService.mutate(
      {
        document: documentInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [this.documentQueryService.document],
      },
    );

  updateDocument = (documentId: string, documentInput: DocumentInput) =>
    this.updateDocumentMutationService.mutate(
      {
        id: documentId,
        document: documentInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [this.documentQueryService.document],
      },
    );

  deleteDocument = (documentId: string) =>
    this.deleteDocumentMutationService.mutate(
      {
        id: documentId,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [this.documentQueryService.document],
      },
    );

  removeFromDashboard = (documentId: string) => {
    const document: Document = this.allDocuments().find((doc) => doc.id === documentId);

    if (document === undefined) {
      // TODO: notification error
    }

    const { id, __typename, ...restDoc } = document;

    this.updateDocument(id, {
      ...restDoc,
      data: {
        ...restDoc.data,
        sortPosition: null,
        showOnDashboard: false,
      },
    } as DocumentInput)
      .pipe(finalize(() => this.updatePositionOnDashboard()))
      .subscribe();
  };

  addToDashboard = (documentId: string) => {
    const document: Document = this.allDocuments().find((doc) => doc.id === documentId);

    if (document === undefined) {
      // TODO: notification error
    }

    const { id, __typename, ...restDoc } = document;

    this.updateDocument(id, {
      ...restDoc,
      data: {
        ...restDoc.data,
        sortPosition: this.dashboardDocuments().length,
        showOnDashboard: true,
      },
    } as DocumentInput).subscribe();
  };

  updatePositionOnDashboard() {
    // TODO: error handling
    const tempDocuments: DashboardComponent[] = this.dashboardDocuments() ?? [];

    tempDocuments.map((doc: DashboardComponent, index: number) => {
      const { id, __typename, ...restDoc } = doc.filterDoc;

      if (restDoc.data.sortPosition !== index) {
        this.updateDocument(id, {
          ...restDoc,
          data: {
            ...restDoc.data,
            sortPosition: index,
          },
        } as DocumentInput).subscribe();
      }
    });
  }

  private sortParams(doc: Document): string {
    const { field, order } = doc.data as any;

    return btoa(JSON.stringify({ field, order }));
  }

  private filterParams(doc: Document): string {
    const { search, rootNode } = doc.data as any;

    return btoa(JSON.stringify({ search, rootNode }));
  }
}
