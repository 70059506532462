import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FilterNodeType } from '@shared/models/types/types.generated';
import {
  FilterCriteriaComponent,
  FilterCriteriaFormModel,
} from '@shared/components/filter/filter-criteria/filter-criteria.component';

export interface FilterNodeFormModel<T> {
  type?: FormControl<FilterNodeType>;
  children?: FormArray<FormGroup<FilterNodeFormModel<T>>>;
  value?: FormGroup<FilterCriteriaFormModel<T>>;
}

@Component({
  selector: 'app-filter-node',
  templateUrl: './filter-node.component.html',
  styleUrls: ['./filter-node.component.scss'],
})
export class FilterNodeComponent<T> {
  @Input()
  formGroup: FormGroup<FilterNodeFormModel<T>>;
  @Input()
  isRoot: boolean = false;
  @Input()
  filterCriteriaTemplate: TemplateRef<any>;
  @Output()
  deleteButtonClicked = new EventEmitter<FilterNodeComponent<T>>();

  FilterNodeType = FilterNodeType;

  get typeControl() {
    return this.formGroup?.controls.type;
  }

  get childrenFormArray() {
    return this.formGroup?.controls.children;
  }

  get valueFormGroup() {
    return this.formGroup?.controls.value;
  }

  addNode(type: FilterNodeType) {
    this.childrenFormArray.push(FilterNodeComponent.createNodeGroup(type));
  }

  deleteSelf() {
    this.deleteButtonClicked.emit(this);
  }

  deleteChild(i: number) {
    this.childrenFormArray.removeAt(i);
  }

  changeCondition($event: { detail: FilterNodeType }) {
    const type = $event.detail;
    if (type === FilterNodeType.And || type === FilterNodeType.Or) {
      this.formGroup.patchValue({
        type,
      });
    }
  }

  static createNodeGroup<T>(type: FilterNodeType): FormGroup<FilterNodeFormModel<T>> {
    const group = new FormGroup<FilterNodeFormModel<T>>(
      {
        type: new FormControl<FilterNodeType>(type),
        children: new FormArray<FormGroup<FilterNodeFormModel<T>>>([]),
      },
      FilterNodeComponent.validateChildrenNotEmpty<T>(),
    );
    if (type === FilterNodeType.Leaf) {
      group.setControl('value', FilterCriteriaComponent.emptyFilterCriteriaGroup<T>());
      group.controls.value.setValidators(Validators.required);
    }
    return group;
  }

  static validateChildrenNotEmpty<E>(): ValidatorFn {
    return (formGroup: FormGroup<FilterNodeFormModel<E>>) => {
      const value = formGroup?.value;
      if (value?.type === FilterNodeType.Leaf) return null;
      else return value?.children?.length > 0 ? null : { childrenEmpty: true };
    };
  }
}
