import { Pipe, PipeTransform } from '@angular/core';
import { DetailStatus } from '@shared/models/enums/status';

@Pipe({
  name: 'toDetailStatus',
})
export class ToDetailStatusPipe implements PipeTransform {
  transform(editMode: boolean, isLoading = false): DetailStatus {
    if (isLoading) return DetailStatus.LOAD;

    return editMode ? DetailStatus.EDIT : DetailStatus.READ;
  }
}
